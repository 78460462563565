import { CardType } from './../../services/theming.service';
import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  OnChanges,
  Inject,
  ElementRef,
  HostListener,
  OnDestroy,
  inject,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
// import { AppService } from '../../app.service';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { AuthenticationComponent } from 'src/app/authentication/authentication.component';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import { ApiService } from 'src/app/pages/common/api.service';
import { GlobalConstants } from 'src/app/pages/common/global-variables';
import { HttpClient } from '@angular/common/http';
import { Location, DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';

import { BsModalService } from 'ngx-bootstrap/modal';
import { SearchComponent } from './search-modal/search-modal.component';
import { AddCardModalComponent } from 'src/app/authentication/addCard-modal/addCard-modal.component';
import { CountryModalComponent } from 'src/app/shared/components/country-modal/country-modal.component';
import { DataTransferService } from 'src/app/shared/services/common/datatransfer.service';
import { AESEncryptDecryptService } from 'src/app/authentication/encryption/AESEncryptDecryptService.service';
import { ToastrService } from 'ngx-toastr';
// import { DetectLocation } from 'src/app/shared/services/common/detect-location.service';
import { appConstants } from 'src/app/app.constants';
import { PaymentService } from 'src/app/shared/services/payment/payment.service';
import { filter, takeUntil } from 'rxjs/operators';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MaintenanceModalComponent } from '../maintenance-modal/maintenance-modal.component';
import moment from 'moment';
import { ThemeService } from '../../services/theming.service';
import { PV_USER_SERVICE } from '@pv-frontend/pv-shared-services/pv-user-service';
import { PvUserServiceImpl } from 'src/app/services/user-service-impl.service';
import { GiftcardService } from 'src/app/pages/gift-cards/service/giftcard.service';
import { CartItem } from 'src/app/pages/gift-cards/checkout/checkout.interface';
import { SideHeaderLink } from './header.interface';
import { S3ImagesConfig } from 'src/app/pages/treasures-homepage/homepage.interface';
import { TreasuresHomepageService } from 'src/app/pages/treasures-homepage/services/treasures-homepage.service';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss'],
  providers: [ApiService, GlobalConstants],
})
export class HeaderComponent implements OnInit, OnChanges, OnDestroy {
  projectSubscription: Subscription;
  @Input() showProfileDropDown: any = false;
  @Input() showAuthentication: boolean = false;
  @Output() hideProfileDropDown = new EventEmitter<any>();

  @Output() reloadTemplate = new EventEmitter();
  @Output() hamburgerToggle = new EventEmitter();
  @Input() refreshHeader: boolean = false;
  @Input() milestoneRewardsExists: boolean = false;
  @Input() selectedCategory;
  @Output() cartResponse = new EventEmitter<object>();
  @ViewChild('modalone') modalone;
  points;
  modalRef;
  IDLE_TIMEOUT = 900;
  _idleSecondsTimer = null;
  _idleSecondsCounter = 0;
  // showAuthentication: any = false;
  openHamburger = false;
  deviceInfo = null;
  isMobile: boolean = false;
  isTablet: boolean = false;
  isDesktopDevice: boolean = false;
  isLoggedIn: boolean = false;
  modalOpen: any = false;
  openMenu: any = false;
  openMenuMob: any = false;
  modalOpenMob: any = false;
  userCards: any = [];

  hideHeader: boolean = false;
  public templateNum: number = 1;
  currentUserCard: any;
  cardType: CardType = 'temp';
  activeCardType: any;
  environment_type: any;
  specialProject: any;
  innerPage = false;
  apps;
  private cartItemSubscription: Subscription;
  public isDiscountedGc: boolean = false;
  public cartItemsArr: CartItem[] = [];
  public isCurrentDomainTreasures: boolean =
    window.location.hostname.includes(appConstants.dbsTreasuresUrlKeyword);
  public delightsSideHeaderLinks: Array<SideHeaderLink> = appConstants.delightsSideHeaderLinks;
  public treasuresLinks: Array<SideHeaderLink> = appConstants.treasures.sideHeaderLinks;
  public treasuresImages: S3ImagesConfig = appConstants.treasures.images;

  @HostListener('unloaded')
  ngOnDestroy() {
    this.dataTransferService.nextMessage(null);
    if (this.projectSubscription) {
      this.projectSubscription.unsubscribe();
    }
    this.elementRef.nativeElement.remove();
    this.destroy$.next();
    this.destroy$.complete();
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth > 1024) {
      this.isDesktopDevice = true;
      this.isTablet = false;
      this.isMobile = false;
      // this.ngOnInit();
    } else if (window.innerWidth > 768) {
      this.isDesktopDevice = false;
      this.isTablet = true;
      this.isMobile = false;
      // this.ngOnInit();
    } else {
      this.isDesktopDevice = false;
      this.isTablet = false;
      this.isMobile = true;
      // this.ngOnInit();
    }
    // this.innerWidth = window.innerWidth;
  }
  hideSome: Boolean = false;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    public _apiService: ApiService,
    public GlobalConstants: GlobalConstants,
    private authenticationService: AuthenticationService,
    private dataTransferService: DataTransferService,
    private location: Location,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    @Inject(DOCUMENT) private document: any,
    private _AESEncryptDecryptService: AESEncryptDecryptService,
    private toastr: ToastrService,
    private elementRef: ElementRef,
    // private _detectLocation: DetectLocation,
    public paymentService: PaymentService,
    public dialog: MatDialog,
    protected themeService: ThemeService,
    @Inject(PV_USER_SERVICE) private pvUserService: PvUserServiceImpl,
    private giftcardService: GiftcardService,
    private treasuresHomepageService: TreasuresHomepageService
  ) {
    this.themeService.setThemes('light', 'initialLoad');
    this.onResize();
    this.environment_type = this.document.location.hostname.includes('sandbox')
      ? 'sandbox'
      : 'prod';
    let pathname = this.document.location.pathname.split('/', 3);
    this.innerPage = pathname[1] && pathname[1] != '' ? true : false;
    this.apps = JSON.parse(JSON.stringify(appConstants.apps));
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (event.url.includes('info')) {
          this.hideSome = true;
        } else {
          this.hideSome = false;
        }
      });
    this.authenticationService.isUserLoggedIn.subscribe((res) => {
      if (res) {
        this.checkAuth();
      } else {
        this.clearAuthStorage();
        this.isLoggedIn = false;
      }
    });
    this.authenticationService.updatePoints.subscribe((res: any) => {
      if (res.points) {
        this.fetchPointsBalance();
      }
    });

    !this.isLoggedIn && this.subscribeToLogin();

    this.cartItemSubscription = this.giftcardService.cartItems.subscribe((data) => {
      if(this.router.url.slice(1)==='offers-on-giftcards' || data.discount) {
        if(this.isLoggedIn) this.giftcardService.clearCartItems(true).then(() => {
          this.pushToCart(data);
        });
      } else {
        if(this.isLoggedIn) this.giftcardService.clearCartItems(false).then(() => {
          this.pushToCart(data);
        });
      }
    }); 
  }

  subscribeToLogin(): void {
    this.pvUserService.loginModalObservable$
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (showLoginModal: boolean) => {
        showLoginModal && this.login();
      }
    })
  }

  private async pushToCart(data): Promise<any> {
    if(data.discount) {
      this.isDiscountedGc = true;
    } else if(this.router.url.includes("/offers-on-giftcards")) {
      this.isDiscountedGc = true;
    } else {
      this.isDiscountedGc = false;
    }
    if(this.cartItemsArr.length > 0) {
      for (const el of this.cartItemsArr) {
        if (el.attributes.details.gift_card_id == data.id && el.attributes.details.recipient_type == data.recipient_type) {
          if (!data.denominations.includes(el.attributes.details.denomination)) {
            await this.deleteFromCart(el.id);
          }
        }
      }
    }

    const formData = {
      'category': 'GiftCard',
      'items': []
    }
    data.valueNum.forEach((obj, index) => {
      formData.items.push({
        'gift_card_id': data.id,
        'name': data.name,
        'denomination': data.denominations[index],
        'quantity': obj,
        'recipient_type': data.recipient_type
      })
    });
    this.giftcardService.pushToCart(formData, this.isDiscountedGc).subscribe((res: any) => {
      if (res && res.data) {
        if(!data.discount) {
          this.cartItemsArr = res.data;
        }
      }
      this.giftcardService.closeModalEvent.emit(true);
      if(!data.discount) {
        this.cartResponse.emit(this.cartItemsArr);
        this.giftcardService.updateCartDetails(this.cartItemsArr);
      }
      this.removeFromCart();      
      setTimeout(() => {
        this.goToCheckout()
      }, 1000);
    }, error => {
      this.toastr.error(error?.error?.errors?.length ? error?.error?.errors[0] : 'Something went wrong!');
    });
  }

  goToCheckout() {
    this.modalone?.hide();
    if(this.isDiscountedGc) {
      this.router.navigate(['offers-on-giftcards', 'checkout'], {
        queryParams: {
          discountedGiftcards: true
        },
        queryParamsHandling: 'merge',
      });
    } else {
      this.router.navigate(['giftcards', 'checkout']);
    }
  }

  async removeFromCart() {
    if (this.cartItemsArr && this.cartItemsArr.length) {
      for (let each of this.cartItemsArr) {
        if (each.attributes.quantity == 0) {
          await this.deleteFromCart(each.id);
        }
      }
    }
  }

  private deleteFromCart(id) {
    return new Promise((resolve) => {
      const deleteData = {
        item_id: id,
      }
      this.giftcardService.deleteFromCart(deleteData, undefined).then((res: any) => {
        if (res && res.data && Array.isArray(res.data)) {
          this.cartItemsArr = res.data;
        }
        // this.apiStatus.emit(true);
        this.cartResponse.emit(this.cartItemsArr);
        this.giftcardService.updateCartDetails(this.cartItemsArr);
        resolve(true);
      });
    });
  }

  redirectToHomePage() {
    this.router.navigate(['/']);
  }

  ngOnInit() {
    let format = 'hh:mm:ss a';
    let beforeTime = moment('12:00:00 am', format);
    let afterTime = moment('3:00:00 am', format);
    if (moment().isBetween(beforeTime, afterTime)) {
      if (environment.production) {
        this.dialog
          .open(MaintenanceModalComponent, {
            panelClass: ['announcementModal'],
          })
          .afterClosed()
          .subscribe(() => {
          });
      }
    }
    if (!window.location.pathname.includes('sso_login')) {
      this.checkAuth();
    }
    // this.checkCountry();
    // this.getInternationalGeoLocation();

    this.dataTransferService.sharedReloadEvent.subscribe((res) => {
      if (res) {
        this.checkAuth();
        // this.checkCountry();
      }
    });

    // this._detectLocation.getInternationalGeoLocation()
  }

  dropdownClosee = false;

  isAppActive(url) {
    return (
      url &&
      window.location.pathname.split('/')?.length > 1 &&
      window.location.pathname.split('/')[1] == url.split('/')[1]
    );
  }

  redirectToHome() {
    this.router.navigate(['/']);
    this.toggleHamburger();
    this.openHamburger = false;
  }

  toggleHamburger() {
    this.openHamburger = !this.openHamburger;
    this.hamburgerToggle.emit(this.openHamburger);
  }

  checkCountry() {
    if (localStorage.getItem('userCountry')) {
      this.userCountry = JSON.parse(localStorage.getItem('userCountry'));
      if (!this.userCountry.attributes) {
        this.userCountry['attributes'] = this.userCountry;
      }
    }
  }
  checkAuth() {
    if (localStorage.getItem('currentUser')) {
      this.isLoggedIn = true;
      this.getCards();
      this.getUserDetails();
    } else {
      this.clearAuthStorage();
      this.isLoggedIn = false;
    }
    if (JSON.parse(localStorage.getItem('card_verified')) == false) {
      this.clearAuthStorage();
    }
  }

  userDetails: any;
  getUserDetails() {
    let userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (userDetails) {
      this.userDetails = userDetails;
      this.isLoggedIn = true;
    } else {
      this._apiService.getUserDetails().subscribe((res) => {
        if (res) {
          localStorage.setItem('userDetails', JSON.stringify(res));
          this.userDetails = res;
          this.isLoggedIn = true;
        }
      });
    }
  }
  clearAuthStorage() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('card_verified');
    localStorage.removeItem('cardSelected');
    localStorage.removeItem('eligible_banners');
    localStorage.removeItem('locationData');
    localStorage.removeItem('currentUserCard');
    localStorage.removeItem('userCard');
    localStorage.removeItem('userCards');
    localStorage.removeItem('expire_at');
    localStorage.removeItem('userDetails');
    localStorage.removeItem('thebin');
    sessionStorage.clear();
  }
  getCards() {
    this.paymentService.pointData$.subscribe((res) => {
      this.points = res;
    }); //read the invoked data or default data
    let userCards = JSON.parse(localStorage.getItem('userCards'));
    if (userCards && userCards.length > 0) {
      this.userCards = userCards;
      this.userCards = this.userCards?.filter(
        (card) => card.attributes.status === 'bin_verified'
      );
      this.checkSelectedCard();
    } else {
      this._apiService.getCards().subscribe(
        (res: any) => {
          this.userCards = res.data;
          this.userCards = this.userCards?.filter(
            (card) => card.attributes.status === 'bin_verified'
          );
          localStorage.setItem('userCards', JSON.stringify(this.userCards));
          this.checkSelectedCard();
        },
        (err) => {
          this.toastr.error(
            'Somthing went wrong in fetching cards, Please try again later.'
          );
        }
      );
    }
  }
  checkSelectedCard() {
    if (
      localStorage.getItem('userCard') &&
      localStorage.getItem('userCard') != 'undefined'
    ) {
      let usercard = JSON.parse(localStorage.getItem('userCard'));
      this.currentUserCard = usercard;
      this.apps = JSON.parse(JSON.stringify(appConstants.apps));
      // setTimeout(() => {
      if(this.isCurrentDomainTreasures) {
        this.themeService.setThemes('treasures');
      } else if (
        this.currentUserCard?.attributes.display_name ==
        appConstants.cardNames.vantage
      ) {
        this.themeService.setThemes('dark');
        this.themeService.setActiveCard('vantage');
        this.apps = this.apps?.filter((app) => app?.specificTo !== 'BFL/Spark');
      } else if (
        this.currentUserCard?.attributes.display_name.includes(
          appConstants.cardNames.mass
        )
      ) {
        this.themeService.setThemes('mass');
        this.themeService.setActiveCard('mass');
        this.apps = this.apps?.filter((app) => app?.specificTo !== 'Vantage');
      } else {
        this.themeService.setThemes('light');
        this.themeService.setActiveCard('bfl');
        this.apps = this.apps?.filter((app) => app?.specificTo !== 'Vantage');
      }
      this.cardType = this.themeService.getActiveCard;

      // }, 10000)

      this.userCards.forEach((x) => {
        if (this.currentUserCard.id && x.id == this.currentUserCard.id) {
          x['currentCard'] = true;
        } else {
          x['currentCard'] = false;
        }
      });
      if (!sessionStorage.getItem('points') || !this.points) {
        this.fetchPointsBalance();
      }
    } else if (
      localStorage.getItem('userCards') &&
      JSON.parse(localStorage.getItem('userCards')).length > 0
    ) {
      let userCards = JSON.parse(localStorage.getItem('userCards'));
      if (userCards && Array.isArray(userCards) && userCards.length > 0) {
        for (const card of userCards) {
          if (card.attributes && card.attributes.status == 'bin_verified' && !this.isCurrentDomainTreasures) {
            localStorage.setItem('userCard', JSON.stringify(card));
            localStorage.setItem('card_verified', 'true');
            this._apiService
              .getCardsWithPoints({ fetch_points: true })
              .subscribe(
                (res: any) => {
                  localStorage.setItem('userCard', JSON.stringify(res.data));
                  sessionStorage.setItem(
                    'points',
                    JSON.stringify(res?.data?.attributes?.points || 0)
                  );
                },
                (err) => {}
              );
            break;
          }
        }
      }
      if (localStorage.getItem('card_verified') == 'false') {
        localStorage.setItem('card_verified', 'false');
      }
    } else if (!sessionStorage.getItem('points') || !this.points) {
      this.fetchPointsBalance();
    }
  }

  fetchPointsBalance() {
    if (!this.isCurrentDomainTreasures) {
      this._apiService
      .getCardsWithPoints({ fetch_points: true })
      .subscribe((res: any) => {
        this.paymentService.changePointsData({
          points: res?.data?.attributes?.points || 0,
          point_redemption_allowed:
            res?.data?.attributes?.point_redemption_allowed,
        });
        let isPointsInSessionStorage = sessionStorage.getItem('points');
        if (!isPointsInSessionStorage && res?.data?.attributes?.points) {
          sessionStorage.setItem('points', res.data.attributes.points);
        }
      },
      (err) => {
        // Error handling done is services.
      });
    }
  }

  checkRedirect() {
    let redirectData = JSON.parse(localStorage.getItem('redirectData'));
    const rederectProject = this.specialProjects.find((x) => {
      return x.id === redirectData.id;
    });
    if (rederectProject && rederectProject.id) {
      localStorage.removeItem('redirectData');
      this.router.navigate([rederectProject.attributes.code, 'offers']);
    } else {
      localStorage.removeItem('redirectData');
    }
  }
  reloadInit() {
    this.ngOnInit();
    this.reloadTemplate.emit();
  }

  ngDoCheck() {
    if (localStorage.getItem('currentUser')) {
      this.isLoggedIn = true;
    } else {
      this.isLoggedIn = false;
    }
  }

  backClicked() {
    this.location.back();
  }

  login(project?) {
    if (project) {
      project['projectId'] = project.id;
      localStorage.setItem('redirectData', JSON.stringify(project));
    } else {
      // localStorage.setItem('headerLogin', 'true');
    }
    if (!this.authenticationService.isAuthenticationComponentOpen) {
      this.authenticationService.isAuthenticationComponentOpen = true;
      this.dialog
        .open(AuthenticationComponent, {
          panelClass: 'authenticationModal',
          data: {
            callbackOnClose: false,
          },
        })
        .afterClosed()
        .subscribe((res) => {
          this.authenticationService.isAuthenticationComponentOpen = false;
        });
    }
  }

  hideAuthentication() {
    this.showAuthentication = false;
  }

  logout() {
    localStorage.clear();
    this.authenticationService.deleteToken().subscribe(
      (res) => {
        this.authenticationService.logout();
        this.router.navigate(['/']);
        window.location.href = '/';
      },
      (error) => {
        this.authenticationService.logout();
        this.router.navigate(['/']);
        window.location.href = '/';
      }
    );
  }

  setCurrentUserCards(card) {
    this.validateCard(card.attributes.bin, card.attributes.bin_type, card);
    // window.location.reload();
  }

  validateCard(bin, bin_type, card) {
    this._apiService.validateCard(bin, bin_type).subscribe((res) => {
      if ( res?.data?.attributes?.bin == bin && res?.data?.attributes?.status == 'bin_verified') {
        let current_user = JSON.parse(localStorage.getItem('currentUser'));
        current_user.token = res.data.attributes.session_token;
        localStorage.setItem('currentUser', JSON.stringify(current_user));
        localStorage.setItem('card_verified', 'true');
        localStorage.setItem('userCard', JSON.stringify(card));
        this.fetchPointsBalance();
        // location.reload();
        location.href = '';
      } else {
        localStorage.setItem('card_verified', 'false');
      }
    });
  }

  headerTimeout: any;
  ngOnChanges(change) {}

  projects: any;
  specialProjects = [];
  specialProjectsDisp = [];
  message: any;
  hasSpecial = false;

  resetShowProfileDropDown() {
    this.hideProfileDropDown.emit(false);
  }

  openSearchModal() {
    const initialState = {
      features: ['All Offers', 'Offers Near Me'],
    };
    var config = {
      backdrop: true,
      ignoreBackdropClick: false,
      class: 'custom-modal-position',
    };
    this.modalRef = this.modalService.show(
      SearchComponent,
      Object.assign({}, config)
    );
    this.modalRef.content.searchItemEvent.subscribe((res) => {});
    this.modalRef.content.selectedItemEvent.subscribe((res) => {
      if (res) {
        this.modalRef.hide();
        if (res.item.type === 'gift_card_list') {
          this.router.navigate(['/giftcards/giftcard-details/' + res.item.id]);
        } else if (res.item.type === 'memberships') {
          this.router.navigate([
            '/memberships/membership-details/' + res.item.id,
          ]);
        } else {
          const merchant = res.item;
          this.router.navigate([
            '/offers/offer-details/' +
              merchant.id +
              '/all/' +
              (merchant.attributes.name
                ? merchant.attributes.name
                    .split(' ')
                    .join('-')
                    .split(',')
                    .join('')
                    .split('(')
                    .join('')
                    .split(')')
                    .join('')
                    .toLowerCase()
                : ''),
          ]);
        }
      }
    });
    this.modalRef.content.hideEvent.subscribe((res) => {
      if (res) {
        this.modalRef.hide();
      }
    });
  }

  getMerchants(name) {}

  openAddcardModal() {
    var config = {
      backdrop: true,
      ignoreBackdropClick: false,
    };
    this.modalRef = this.modalService.show(
      AddCardModalComponent,
      Object.assign({}, config, {})
    );
    this.modalRef.content.hideEvent.subscribe((res) => {
      if (res) {
        this.modalRef.hide();
      }
    });
  }

  //international logic starts
  showLocationError: any;
  userLocation: any;
  userCountry: any;
  allInternationalCountries = [];
  allCountries: any;

  getAllInternationalCountries(
    checkLocation = true,
    openLocationModal = false
  ) {
    this._apiService.getAllCountries().subscribe((res: any) => {
      this.allInternationalCountries = res.data;
      this.allInternationalCountries.sort(function (a, b) {
        var textA = a.attributes.name.toUpperCase();
        var textB = b.attributes.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
      this.dataTransferService.nextCountries(this.allInternationalCountries);
      if (checkLocation) {
        this.checkLocation();
      }
      if (openLocationModal) {
        this.showLocationModal();
      }
      // this.allCountries = res.data;
    });
  }

  getInternationalGeoLocation() {
    if (!localStorage.getItem('userCountry')) {
      if (
        !this.allInternationalCountries ||
        (this.allInternationalCountries &&
          this.allInternationalCountries.length < 2)
      ) {
        this.getAllInternationalCountries();
      } else {
        this.checkLocation();
      }
    }
  }
  checkLocation() {
    if (localStorage.getItem('userLocation')) {
      this.userLocation = JSON.parse(localStorage.getItem('userLocation'));
      this.checkCordinates(
        this.userLocation.latitude + ',' + this.userLocation.longitude
      );
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.userLocation = position.coords;
          localStorage.setItem(
            'userLocation',
            JSON.stringify({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            })
          );
          this.checkCordinates(
            this.userLocation.latitude + ',' + this.userLocation.longitude
          );
        },
        () => {
          this.showLocationModal(false);
        }
      );
    } else {
      this.showLocationModal(false);
    }
  }
  checkCordinates(param) {
    this._apiService.checkCordinates(param).subscribe(
      (res: any) => {
        if (res && res.results[0] && res.results[0].components) {
          let county;
          let country = res.results[0].components.country.toLowerCase();
          if (res.results[0].components.county) {
            county = res.results[0].components.county.toLowerCase();
          } else if (res.results[0].components.county) {
            county = res.results[0].components.city.toLowerCase();
          } else if (res.results[0].components.county) {
            county = res.results[0].components.state.toLowerCase();
          }
          this.processInternationalLocation(county, country);
        }
      },
      () => {
        this.showLocationModal(false);
      }
    );
  }
  showLocationModal(allowClose = true) {
    const initialState = {
      allCountries: this.allInternationalCountries
        ? this.allInternationalCountries
        : null,
    };
    var config = {
      backdrop: true,
      ignoreBackdropClick: allowClose ? false : true,
      keyboard: allowClose ? true : false,
    };
    this.modalRef = this.modalService.show(
      CountryModalComponent,
      Object.assign({}, config, { initialState })
    );
    this.modalRef.content.hideEvent.subscribe((res) => {
      if (res) {
        this.modalRef.hide();
      }
    });
  }
  processInternationalLocation(county, country) {
    this.userCountry = this.allInternationalCountries.find((x) => {
      return x.attributes.name.toLowerCase() == country;
    });
    if (this.userCountry) {
      this.userCountry = this.userCountry;
      localStorage.setItem('userCountry', JSON.stringify(this.userCountry));

      // location.reload();
      this.dataTransferService.nextReloadEvent(true);
    } else {
      this.showLocationModal(false);
    }
  }

  goTo(project, type) {
    if (this.isLoggedIn) {
      if (type === 'project') {
        if (project && project.attributes && project.attributes.merchant) {
          this.router.navigate(['offer', project.attributes.code]);
        } else {
          this.router.navigate([project.attributes.code, 'offers']);
        }
      } else if (type === 'home') {
        let qParams: Params = {};
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: qParams,
          queryParamsHandling: '',
        });
      }
    } else {
      this.login(project);
    }
  }
  protected navigateToRewards(): void {
    this.toggleHamburger();
    this.router.navigateByUrl('booking/home?app=Exclusive%20Rewards');
  }
  protected navigateToBookings(): void {
    if(this.openHamburger) {
      this.toggleHamburger();
    }
    if (this.isCurrentDomainTreasures) {
      this.router.navigateByUrl('booking/home?app=Luxury%20Brand%20Vouchers');
    } else {
      this.router.navigateByUrl('booking/home');
    }
  }

  public handleTreasuresLinkClick(action: string): void {
    if (action.includes('/')) {
      this.router.navigateByUrl(action);
      this.toggleHamburger();
    } else {
      this.router.navigate(['/']);
      this.toggleHamburger();
      setTimeout(() => {
        this.treasuresHomepageService.emitToggleBenefitForm(true, action);
      }, 500);
    }
  }
}
