<header
  class="dbsv2-header"
  [ngClass]="!isCurrentDomainTreasures && 'header-shadow'"
>
  <div (click)="redirectToHomePage()" class="logo">
    <img
      *ngIf="isLoggedIn"
      [src]="
        GlobalConstants.data.logo[
          cardType === 'temp' ? 'dark' : themeService.getActiveThemeName
        ]?.modal_url
      "
      alt=""
      [ngClass]="cardType === 'temp' ? 'dark' : themeService.getActiveThemeName"
    />
    <img
      *ngIf="isCurrentDomainTreasures || !isLoggedIn"
      [ngClass]="isCurrentDomainTreasures && 'treasures-logo'"
      [src]="
        isCurrentDomainTreasures
          ? treasuresImages.treasuresLogo
          : '../../../../assets/DBS/DBS_logo.svg'
      "
    />
  </div>
  <ng-container *ngIf="!hideSome">
    <div
      *ngIf="isLoggedIn && !isCurrentDomainTreasures"
      class="search-cta-mobile"
      (click)="openSearchModal()"
    >
      <svg
        width="18"
        height="18"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.0994 20.1942C12.5711 20.1935 14.9558 19.2805 16.7974 17.6299L22.9867 23.8266C23.0982 23.9378 23.2492 24.0001 23.4065 24C23.5639 23.9998 23.7148 23.9372 23.826 23.8258C23.9373 23.7144 23.9999 23.5634 24 23.4058C24.0001 23.2483 23.9379 23.0971 23.8268 22.9855L17.6374 16.7888C19.1667 15.0587 20.0571 12.856 20.1601 10.5481C20.2632 8.24013 19.5726 5.96661 18.2037 4.10686C16.8347 2.2471 14.8702 0.913592 12.6379 0.328823C10.4055 -0.255945 8.0404 -0.0566021 5.93713 0.893592C3.83385 1.84379 2.11964 3.48736 1.08051 5.55008C0.0413807 7.6128 -0.259817 9.9699 0.227173 12.2281C0.714163 14.4863 1.95989 16.509 3.75649 17.9587C5.55309 19.4084 7.79192 20.1975 10.0994 20.1942ZM10.0994 1.16102C11.8619 1.16102 13.5848 1.68428 15.0502 2.66462C16.5156 3.64496 17.6578 5.03835 18.3323 6.6686C19.0067 8.29885 19.1832 10.0927 18.8394 11.8234C18.4955 13.5541 17.6468 15.1438 16.4006 16.3915C15.1543 17.6392 13.5665 18.489 11.8379 18.8332C10.1093 19.1775 8.31754 19.0008 6.68923 18.3255C5.06092 17.6502 3.66918 16.5067 2.69001 15.0395C1.71084 13.5723 1.1882 11.8474 1.1882 10.0828C1.19086 7.71744 2.13057 5.44969 3.80117 3.7771C5.47176 2.10451 7.73682 1.16368 10.0994 1.16102Z"
          fill="#787878"
        />
      </svg>
    </div>
    <div
      *ngIf="isLoggedIn && !isCurrentDomainTreasures"
      class="search-cta-desktop"
    >
      <div class="input-container" (click)="openSearchModal()">
        <svg
          width="18"
          height="18"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.0994 20.1942C12.5711 20.1935 14.9558 19.2805 16.7974 17.6299L22.9867 23.8266C23.0982 23.9378 23.2492 24.0001 23.4065 24C23.5639 23.9998 23.7148 23.9372 23.826 23.8258C23.9373 23.7144 23.9999 23.5634 24 23.4058C24.0001 23.2483 23.9379 23.0971 23.8268 22.9855L17.6374 16.7888C19.1667 15.0587 20.0571 12.856 20.1601 10.5481C20.2632 8.24013 19.5726 5.96661 18.2037 4.10686C16.8347 2.2471 14.8702 0.913592 12.6379 0.328823C10.4055 -0.255945 8.0404 -0.0566021 5.93713 0.893592C3.83385 1.84379 2.11964 3.48736 1.08051 5.55008C0.0413807 7.6128 -0.259817 9.9699 0.227173 12.2281C0.714163 14.4863 1.95989 16.509 3.75649 17.9587C5.55309 19.4084 7.79192 20.1975 10.0994 20.1942ZM10.0994 1.16102C11.8619 1.16102 13.5848 1.68428 15.0502 2.66462C16.5156 3.64496 17.6578 5.03835 18.3323 6.6686C19.0067 8.29885 19.1832 10.0927 18.8394 11.8234C18.4955 13.5541 17.6468 15.1438 16.4006 16.3915C15.1543 17.6392 13.5665 18.489 11.8379 18.8332C10.1093 19.1775 8.31754 19.0008 6.68923 18.3255C5.06092 17.6502 3.66918 16.5067 2.69001 15.0395C1.71084 13.5723 1.1882 11.8474 1.1882 10.0828C1.19086 7.71744 2.13057 5.44969 3.80117 3.7771C5.47176 2.10451 7.73682 1.16368 10.0994 1.16102Z"
            fill="#787878"
          />
        </svg>
        <input
          type="text"
          readonly
          placeholder="Search with merchant name, product and more"
        />
      </div>
    </div>
    <div
      *ngIf="!isMobile && !isTablet && isLoggedIn && !isCurrentDomainTreasures"
      class="links-contain"
    >
      <a routerLink="/offers-on-giftcards" class="header-links head-link">
        Discount on Gift Cards
        <sup class="new-link ms-1">New</sup>
      </a>
      <a routerLink="/offers" class="header-links head-link">Deals & Offers</a>
      <div class="header-links profile-drop-sec" dropdown>
        <div class="head-link" dropdownToggle>
          Redeem Cash Points
          <!-- <svg class="mar-left-9" xmlns="http://www.w3.org/2000/svg" width="8" height="7" viewBox="0 0 8 7" fill="none">
            <path d="M8 0H0L4 7L8 0Z" fill="white"/>
          </svg> -->
        </div>
        <div
          class="dropdown-menu dropdown-menu-shadow profile-drop"
          *dropdownMenu
        >
          <a routerLink="points-xchange/home" class="option-box block">
            Transfer Your Points
          </a>
          <a routerLink="/giftcards" class="option-box block">Buy Gift Cards</a>
          <a routerLink="/memberships" class="option-box block">
            Buy Memberships
          </a>
          <a href="/flights" class="option-box block">Book Flights</a>
          <a href="/hotels" class="option-box block">Book Hotels</a>
          <a
            *ngIf="!this.themeService?.isDarkTheme"
            href="/bus"
            class="option-box block"
          >
            Book Bus Tickets
          </a>
          <a href="/charitable-donations" class="option-box block">
            Donate to Charity
          </a>
        </div>
      </div>
    </div>
  </ng-container>

  <section [class.open]="openHamburger" class="side-menu fixed">
    <div class="side-menu-header">
      <div>
        <!-- <img class="img-fluid logo-img client-logo" alt="" loading="lazy" src="./assets/DBS_whiteLogo.webp"> -->
        <img
          class="img-fluid logo-img client-logo"
          alt=""
          loading="lazy"
          [src]="
            GlobalConstants.data.logo[this.themeService.getActiveThemeName]
              ?.white_logo
          "
        />
      </div>
      <div class="close-hamb">
        <svg
          class="m-auto"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 25 25"
          fill="none"
          (click)="toggleHamburger()"
        >
          <circle cx="12.5" cy="12.5" r="12.5" fill="#D6DFE8" />
          <path
            d="M17 8.90643L16.0936 8L12.5 11.5936L8.90643 8L8 8.90643L11.5936 12.5L8 16.0936L8.90643 17L12.5 13.4064L16.0936 17L17 16.0936L13.4064 12.5L17 8.90643Z"
            fill="#64707C"
          />
        </svg>
      </div>
    </div>
    <div class="user-sec-hamburger">
      <span class="ms-1">
        Hi, {{ userDetails?.full_name?.split(' ')[0] }}&nbsp;&nbsp;
      </span>
      👋
    </div>
    <div *ngIf="isCurrentDomainTreasures" class="px-3 pb-1 text-treasures">
      {{ userDetails?.client_tier }}
    </div>
    <section *ngIf="!isCurrentDomainTreasures">
      <div
        class="cards"
        *ngFor="let card of userCards"
        [ngClass]="{ 'active-card': card.currentCard }"
        (click)="setCurrentUserCards(card)"
      >
        <div class="img-sec">
          <svg
            class="visa-drop-ic mar-right-10"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            version="1.1"
            id="Capa_1"
            x="0px"
            y="0px"
            width="548.176px"
            height="548.176px"
            viewBox="0 0 548.176 548.176"
            style="enable-background: new 0 0 548.176 548.176"
            xml:space="preserve"
          >
            <g>
              <g>
                <path
                  d="M534.754,68.238c-8.945-8.945-19.698-13.417-32.258-13.417H45.681c-12.562,0-23.313,4.471-32.264,13.417    C4.471,77.185,0,87.936,0,100.499v347.173c0,12.566,4.471,23.318,13.417,32.264c8.951,8.946,19.702,13.419,32.264,13.419h456.815    c12.56,0,23.312-4.473,32.258-13.419c8.945-8.945,13.422-19.697,13.422-32.264V100.499    C548.176,87.936,543.699,77.185,534.754,68.238z M511.627,447.679c0,2.478-0.903,4.613-2.711,6.427    c-1.807,1.8-3.949,2.703-6.42,2.703H45.681c-2.473,0-4.615-0.903-6.423-2.71c-1.807-1.813-2.712-3.949-2.712-6.427V274.088    h475.082V447.679z M511.627,164.449H36.545v-63.954c0-2.474,0.902-4.611,2.712-6.423c1.809-1.803,3.951-2.708,6.423-2.708h456.815    c2.471,0,4.613,0.901,6.42,2.708c1.808,1.812,2.711,3.949,2.711,6.423V164.449L511.627,164.449z"
                />
                <rect x="73.092" y="383.719" width="73.089" height="36.548" />
                <rect x="182.728" y="383.719" width="109.634" height="36.548" />
              </g>
            </g>
          </svg>
        </div>
        <div class="info-sec">
          <div class="bin">
            {{ card?.attributes?.bin | slice: 0 : 4 }}
            XXXX XXXX {{ card?.attributes?.bin | slice: -4 }}
          </div>
          <div
            class="name"
            [innerHtml]="card?.attributes.display_name.toLowerCase()"
          ></div>
        </div>
      </div>
    </section>

    <section class="c2E2E2E">
      <div class="each-option d-block">
        <div
          (click)="redirectToHome()"
          class="d-flex align-items-center justify-content-start pad-bot-6 fnt-16 capitalize"
        >
          <img
            class="special-brand-filter"
            src="../../../../assets/DBS/hamburger-icons/home.svg"
          />
          <a class="option-box block pointer">Home</a>
        </div>
      </div>
      <div *ngIf="!isCurrentDomainTreasures">
        <a
          *ngFor="let option of delightsSideHeaderLinks"
          class="each-option d-block"
          [href]="option?.link"
          (click)="(option.clickFunction)"
        >
          <div
            class="d-flex align-items-center justify-content-start pad-bot-6 fnt-16 capitalize"
          >
            <img class="special-brand-filter" [src]="option.src" />
            <a class="option-box block pointer">
              {{ option.title }}
              <sup *ngIf="option.superscript" class="new-link ms-1">
                {{ option.superscript }}
              </sup>
            </a>
          </div>
        </a>

        <div
          class="each-option d-flex align-items-center justify-content-between pad-bot-6 fnt-16 capitalize"
          (click)="dropdownClosee = !dropdownClosee"
        >
          <div class="d-flex align-center justify-content-start">
            <img
              class="special-brand-filter"
              src="../../../../assets/DBS/hamburger-icons/redeem-points.svg"
            />
            <a class="option-box block pointer">Redeem Cash Points</a>
          </div>
          <span class="icon icon-down-open-mini" *ngIf="dropdownClosee"></span>
          <span class="icon icon-up-open-mini" *ngIf="!dropdownClosee"></span>
        </div>
        <section class="second-level" *ngIf="!dropdownClosee">
          <ng-container *ngFor="let app of apps">
            <a
              *ngIf="app?.url !== '/exclusive-rewards'"
              [ngClass]="{ active: isAppActive(app?.url) }"
              class="each-option d-block"
              [routerLink]="app?.url"
              (click)="toggleHamburger()"
            >
              <div
                class="inner d-flex align-items-center justify-content-start fnt-16 capitalize mar-left-39"
              >
                <img
                  class="special-brand-filter"
                  src="https://images.poshvine.com/dbs/hamburger-{{
                    app.icon
                  }}.svg"
                />
                <a [routerLink]="app?.url" class="option-box block pointer">
                  {{ app?.cusname }}
                </a>
              </div>
            </a>
          </ng-container>
        </section>
      </div>

      <div *ngIf="isCurrentDomainTreasures">
        <a
          *ngFor="let link of treasuresLinks"
          class="each-option d-block"
          (click)="handleTreasuresLinkClick(link?.action)"
        >
          <div
            class="d-flex align-items-center justify-content-start pad-bot-6 fnt-16 capitalize"
          >
            <img class="special-brand-filter" [src]="link.src" />
            <a class="option-box block pointer">{{ link.title }}</a>
          </div>
        </a>
      </div>

      <a class="each-option d-block" (click)="navigateToBookings()">
        <div
          class="d-flex align-items-center justify-content-start pad-bot-6 fnt-16 capitalize"
        >
          <img
            class="special-brand-filter"
            src="../../../../assets/DBS/hamburger-icons/redemption-history.svg"
          />
          <a class="option-box block pointer">Redemption History</a>
        </div>
      </a>
      <a class="each-option d-block" (click)="logout()">
        <div
          class="d-flex align-items-center justify-content-start pad-bot-6 fnt-16 capitalize"
        >
          <img
            class="special-brand-filter"
            src="../../../../assets/DBS/hamburger-icons/logout.svg"
          />
          <a class="option-box block pointer">Logout</a>
        </div>
      </a>
      <a class="extra-content" href="/info/customer-support">Support</a>
      <a class="extra-content" href="/info/privacy-policy">Privacy Policy</a>
      <a class="extra-content" href="/info/terms-and-conditions">
        Terms and condition
      </a>
    </section>
  </section>
  <div class="overlay" *ngIf="openHamburger" (click)="toggleHamburger()"></div>

  <div
    class="h100 relative d-flex flex-column align-center justify-content-center"
    *ngIf="isLoggedIn"
  >
    <div dropdown class="h100">
      <div
        *ngIf="cardType !== 'temp'"
        class="w100 h100 flex align-center pointer fnt-16 f500 justify-content-center mb-1"
        dropdownToggle
      >
        <div *ngIf="!isCurrentDomainTreasures" class="user-part">
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.9978 1.81251C10.9752 1.81251 10.9544 1.8252 10.9459 1.84178L8.02274 7.63869C7.92162 7.83922 7.72499 7.97411 7.50152 7.99625L1.85658 8.55545C1.80892 8.6046 1.81335 8.62437 1.82653 8.63743L6.46719 13.2338C6.6357 13.4007 6.70105 13.6456 6.6381 13.8743L4.9204 20.1143C4.91483 20.1353 4.92208 20.1576 4.93892 20.1713C4.95576 20.1849 4.97906 20.1875 5.00201 20.1759L10.7066 17.3505C10.8901 17.2597 11.1055 17.2597 11.289 17.3505L17.0051 20.1812C17.0244 20.191 17.0477 20.1884 17.0646 20.1748C17.0758 20.1656 16.5094 18.0666 15.3654 13.8778C15.3025 13.6492 15.3677 13.4044 15.5361 13.2375L20.1733 8.64109C20.1782 8.5728 20.1621 8.5605 20.1331 8.558L14.4985 7.99975C14.2751 7.97762 14.0785 7.84282 13.9774 7.6424L11.0512 1.84461C11.0407 1.82488 11.0202 1.81251 10.9978 1.81251ZM7.01329 6.72569L9.77589 1.2472C10.0107 0.788538 10.4826 0.5 10.9978 0.5C11.5131 0.5 11.9849 0.788538 12.2197 1.2472L14.9866 6.72918L20.2521 7.25095C20.7812 7.29492 21.2364 7.64267 21.4179 8.14162C21.5995 8.64057 21.4742 9.19956 21.0972 9.57331L16.7326 13.8995L18.3515 19.781C18.4897 20.3012 18.3098 20.8541 17.8921 21.1935C17.4743 21.5329 16.8963 21.5956 16.4191 21.3556L10.9978 18.6709L5.58802 21.3503C5.10716 21.5921 4.52919 21.5294 4.11143 21.19C3.69367 20.8506 3.51382 20.2977 3.65351 19.7717L5.27085 13.8962L0.902848 9.56982C0.525754 9.19606 0.400507 8.63707 0.582061 8.13812C0.763615 7.63917 1.2188 7.29142 1.73755 7.24839L7.01329 6.72569Z"
              fill="white"
            />
          </svg>
          <div
            class="d-flex flex-column align-start flex-column justify-content-start"
          >
            <div class="user-points">
              {{ points?.points | indianCurrency }}
            </div>
            <div class="accenttwo text-nowrap cash-points">Points</div>
          </div>
        </div>
      </div>
      <div
        class="dropdown-menu dropdown-menu-shadow w100 pad-7 profile-drop"
        *dropdownMenu
      >
        <div class="user-sec" style="padding: 10px 20px">
          👋
          <span class="ms-1">
            Hi, {{ userDetails?.full_name?.split(' ')[0] }}
          </span>
        </div>
        <div
          class="option-box flex align-baseline pointer"
          *ngFor="let card of userCards"
          [ngStyle]="{
            'background-color': card.currentCard
              ? GlobalConstants.data.colors.active_card_bg_color
              : '#fff',
          }"
          (click)="setCurrentUserCards(card)"
        >
          <div>
            <svg
              class="visa-drop-ic mar-right-10"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.1"
              id="Capa_1"
              x="0px"
              y="0px"
              width="548.176px"
              height="548.176px"
              viewBox="0 0 548.176 548.176"
              style="enable-background: new 0 0 548.176 548.176"
              xml:space="preserve"
            >
              <g>
                <g>
                  <g>
                    <path
                      d="M534.754,68.238c-8.945-8.945-19.698-13.417-32.258-13.417H45.681c-12.562,0-23.313,4.471-32.264,13.417    C4.471,77.185,0,87.936,0,100.499v347.173c0,12.566,4.471,23.318,13.417,32.264c8.951,8.946,19.702,13.419,32.264,13.419h456.815    c12.56,0,23.312-4.473,32.258-13.419c8.945-8.945,13.422-19.697,13.422-32.264V100.499    C548.176,87.936,543.699,77.185,534.754,68.238z M511.627,447.679c0,2.478-0.903,4.613-2.711,6.427    c-1.807,1.8-3.949,2.703-6.42,2.703H45.681c-2.473,0-4.615-0.903-6.423-2.71c-1.807-1.813-2.712-3.949-2.712-6.427V274.088    h475.082V447.679z M511.627,164.449H36.545v-63.954c0-2.474,0.902-4.611,2.712-6.423c1.809-1.803,3.951-2.708,6.423-2.708h456.815    c2.471,0,4.613,0.901,6.42,2.708c1.808,1.812,2.711,3.949,2.711,6.423V164.449L511.627,164.449z"
                    />
                    <rect
                      x="73.092"
                      y="383.719"
                      width="73.089"
                      height="36.548"
                    />
                    <rect
                      x="182.728"
                      y="383.719"
                      width="109.634"
                      height="36.548"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div>
            <div class="visa-drop-num">
              {{ card?.attributes?.bin | slice: 0 : 4 }}
              XXXX XXXX {{ card?.attributes?.bin | slice: -4 }}
            </div>
            <div
              class="fnt-12 capitalize"
              [innerHtml]="card?.attributes.display_name.toLowerCase()"
            ></div>
          </div>
        </div>

        <div class="option-box pointer flex align-center">
          <span
            class="icon icon-bookings reward-drop-ic mar-right-10"
            style="font-size: 20px"
          ></span>
          <a (click)="navigateToBookings()" class="visa-drop-num f400 c222">
            Booking/Redemption History
          </a>
        </div>
        <div
          *ngIf="!isMobile && isLoggedIn"
          (click)="logout()"
          class="option-box pointer flex align-center"
        >
          <svg
            class="logout-ic mar-right-10"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            version="1.1"
            id="Capa_1"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            style="enable-background: new 0 0 512 512"
            xml:space="preserve"
          >
            <g>
              <g>
                <g>
                  <path
                    d="M255.15,468.625H63.787c-11.737,0-21.262-9.526-21.262-21.262V64.638c0-11.737,9.526-21.262,21.262-21.262H255.15    c11.758,0,21.262-9.504,21.262-21.262S266.908,0.85,255.15,0.85H63.787C28.619,0.85,0,29.47,0,64.638v382.724    c0,35.168,28.619,63.787,63.787,63.787H255.15c11.758,0,21.262-9.504,21.262-21.262    C276.412,478.129,266.908,468.625,255.15,468.625z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M505.664,240.861L376.388,113.286c-8.335-8.25-21.815-8.143-30.065,0.213s-8.165,21.815,0.213,30.065l92.385,91.173    H191.362c-11.758,0-21.262,9.504-21.262,21.262c0,11.758,9.504,21.263,21.262,21.263h247.559l-92.385,91.173    c-8.377,8.25-8.441,21.709-0.213,30.065c4.167,4.21,9.653,6.336,15.139,6.336c5.401,0,10.801-2.041,14.926-6.124l129.276-127.575    c4.04-3.997,6.336-9.441,6.336-15.139C512,250.302,509.725,244.88,505.664,240.861z"
                  />
                </g>
              </g>
            </g>
          </svg>
          <span class="visa-drop-num f400 c222">Logout</span>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="isLoggedIn && !isMobile"
    class="user-profile-icon h100 flex align-center justify-content-center pad-left-20 mar-left-15 mar-right-15"
    dropdownToggle
    dropdown
  >
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="inherit"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        style="fill: inherit"
        d="M11 0C4.928 0 0 4.928 0 11C0 17.072 4.928 22 11 22C17.072 22 22 17.072 22 11C22 4.928 17.072 0 11 0ZM11 3.3C12.826 3.3 14.3 4.774 14.3 6.6C14.3 8.426 12.826 9.9 11 9.9C9.174 9.9 7.7 8.426 7.7 6.6C7.7 4.774 9.174 3.3 11 3.3ZM11 18.92C8.25 18.92 5.819 17.512 4.4 15.378C4.433 13.189 8.8 11.99 11 11.99C13.189 11.99 17.567 13.189 17.6 15.378C16.181 17.512 13.75 18.92 11 18.92Z"
      />
    </svg>
    <div
      class="dropdown-menu dropdown-menu-shadow w100 pad-7 profile-drop"
      *dropdownMenu
    >
      <div class="user-sec" style="padding: 10px 20px">
        👋
        <span class="ms-1">
          Hi, {{ userDetails?.full_name?.split(' ')[0] }}
        </span>
      </div>
      <div *ngIf="isCurrentDomainTreasures" class="px-3 pb-1 text-treasures">
        {{ userDetails?.client_tier }}
      </div>
      <section *ngIf="!isCurrentDomainTreasures">
        <div
          class="option-box flex align-baseline pointer"
          *ngFor="let card of userCards"
          [ngStyle]="{
            'background-color': card.currentCard
              ? GlobalConstants.data.colors.active_card_bg_color
              : '#fff',
          }"
          (click)="setCurrentUserCards(card)"
        >
          <div>
            <svg
              class="visa-drop-ic mar-right-10"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.1"
              id="Capa_1"
              x="0px"
              y="0px"
              width="548.176px"
              height="548.176px"
              viewBox="0 0 548.176 548.176"
              style="enable-background: new 0 0 548.176 548.176"
              xml:space="preserve"
            >
              <g>
                <g>
                  <g>
                    <path
                      d="M534.754,68.238c-8.945-8.945-19.698-13.417-32.258-13.417H45.681c-12.562,0-23.313,4.471-32.264,13.417    C4.471,77.185,0,87.936,0,100.499v347.173c0,12.566,4.471,23.318,13.417,32.264c8.951,8.946,19.702,13.419,32.264,13.419h456.815    c12.56,0,23.312-4.473,32.258-13.419c8.945-8.945,13.422-19.697,13.422-32.264V100.499    C548.176,87.936,543.699,77.185,534.754,68.238z M511.627,447.679c0,2.478-0.903,4.613-2.711,6.427    c-1.807,1.8-3.949,2.703-6.42,2.703H45.681c-2.473,0-4.615-0.903-6.423-2.71c-1.807-1.813-2.712-3.949-2.712-6.427V274.088    h475.082V447.679z M511.627,164.449H36.545v-63.954c0-2.474,0.902-4.611,2.712-6.423c1.809-1.803,3.951-2.708,6.423-2.708h456.815    c2.471,0,4.613,0.901,6.42,2.708c1.808,1.812,2.711,3.949,2.711,6.423V164.449L511.627,164.449z"
                    />
                    <rect
                      x="73.092"
                      y="383.719"
                      width="73.089"
                      height="36.548"
                    />
                    <rect
                      x="182.728"
                      y="383.719"
                      width="109.634"
                      height="36.548"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div>
            <div class="visa-drop-num">
              {{ card?.attributes?.bin | slice: 0 : 4 }}
              XXXX XXXX {{ card?.attributes?.bin | slice: -4 }}
            </div>
            <div
              class="fnt-12 capitalize"
              [innerHtml]="card?.attributes.display_name.toLowerCase()"
            ></div>
          </div>
        </div>
      </section>

      <div class="option-box pointer flex align-center">
        <span
          class="icon icon-bookings reward-drop-ic mar-right-10"
          style="font-size: 20px"
        ></span>
        <a (click)="navigateToBookings()" class="visa-drop-num f400 c222">
          Booking/Redemption History
        </a>
      </div>

      <div
        *ngIf="!isMobile && isLoggedIn"
        (click)="logout()"
        class="option-box pointer flex align-center"
      >
        <svg
          class="logout-ic mar-right-10"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          version="1.1"
          id="Capa_1"
          x="0px"
          y="0px"
          viewBox="0 0 512 512"
          style="enable-background: new 0 0 512 512"
          xml:space="preserve"
        >
          <g>
            <g>
              <g>
                <path
                  d="M255.15,468.625H63.787c-11.737,0-21.262-9.526-21.262-21.262V64.638c0-11.737,9.526-21.262,21.262-21.262H255.15    c11.758,0,21.262-9.504,21.262-21.262S266.908,0.85,255.15,0.85H63.787C28.619,0.85,0,29.47,0,64.638v382.724    c0,35.168,28.619,63.787,63.787,63.787H255.15c11.758,0,21.262-9.504,21.262-21.262    C276.412,478.129,266.908,468.625,255.15,468.625z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  d="M505.664,240.861L376.388,113.286c-8.335-8.25-21.815-8.143-30.065,0.213s-8.165,21.815,0.213,30.065l92.385,91.173    H191.362c-11.758,0-21.262,9.504-21.262,21.262c0,11.758,9.504,21.263,21.262,21.263h247.559l-92.385,91.173    c-8.377,8.25-8.441,21.709-0.213,30.065c4.167,4.21,9.653,6.336,15.139,6.336c5.401,0,10.801-2.041,14.926-6.124l129.276-127.575    c4.04-3.997,6.336-9.441,6.336-15.139C512,250.302,509.725,244.88,505.664,240.861z"
                />
              </g>
            </g>
          </g>
        </svg>
        <span class="visa-drop-num f400 c222">Logout</span>
      </div>
    </div>
  </div>

  <div
    *ngIf="(isMobile || isTablet) && !hideSome && isLoggedIn"
    class="hamburger relative"
    [ngStyle]="isLoggedIn ? { 'margin-left': '0px' } : ''"
    (click)="toggleHamburger()"
  >
    <div class="bar1"></div>
    <div class="bar2"></div>
    <div class="bar3"></div>
  </div>
  <div
    *ngIf="!isLoggedIn && GlobalConstants.data.login_button_needed"
    class="login-btn"
    (click)="login()"
  >
    Login
  </div>
</header>
<!-- Mobile header -->
